.content-title-form {
  text-align: center;
  position: relative;
  margin-bottom: rem(30px);
  padding-bottom: rem(15px);
  margin-top: 0;
  color: #34BDBD;

  /* &:after {
        content: '';
        display: block;
        position: absolute;
        width: rem(40px);
        background: var(--#{$prefix}component-color);
        height: rem(2px);
        bottom: 0;
        
        @if $enable-rtl {
            right: 50%;
            margin-right: rem(-20px);
        } @else {
            left: 50%;
            margin-left: rem(-20px);
        } */
}
