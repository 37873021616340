.footer-logo-sb {
  width: 7rem;
  margin: 0 auto;
}
.section-space {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.col-space {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.section-title {
  text-align: center;
  position: relative;
  padding-bottom: 1rem;
}
.section-content {
  font-size: 1rem;
}

@media screen and (max-width: 768px) {
  .home-content-sb {
    top: 40%;
  }
  .footer-logo-sb {
    width: 6rem;
    margin: 0 auto;
  }
  .section-space {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .col-space {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .section-title {
    padding-bottom: 0.5rem;
  }
  .section-content {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 770px) and (max-width: 900px) {
  .home-content-sb {
    top: 40%;
  }
  .footer-logo-sb {
    width: 6rem;
    margin: 0 auto;
  }
  .section-content {
    font-size: 0.9rem;
  }
}
