.home-content-sb {
    position: absolute;
    top: 15%;
    left: 0;
    right: 0;
    margin-top: rem(-120px);
    padding: 0 rem(15px);
    text-align: center;
  }
  .home-logo-sb {
    width: 50%;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .home-content-sb {
      top: 30%;
    }
    .home-logo-sb {
        width: 80%;
    }
  }
  
  @media screen and (min-width: 770px) and (max-width: 900px) {
    .home-content-sb {
      top: 30%;
    }
    .home-logo-sb {
        width: 80%;
    }
  }
  