@keyframes slideInX {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInInvX {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes slideInY {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes slideInInvY {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .content-animated-1 {
    animation: slideInX 1s both, fadeIn 2s both;
  }
  .content-animated-2 {
    animation: slideInInvX 1s both, fadeIn 2s both;
  }
  .content-animated-3 {
    animation: slideInY 1s both, fadeIn 2s both;
  }
  .content-animated-4 {
    animation: slideInInvY 1s both, fadeIn 2s both;
  }
  .finishAnimated {
    animation: none !important;
  }
  